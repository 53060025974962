




















































































































































import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import InsertionOrder from '@/models/InsertionOrder'
import Api from '@/models/Api'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import moment from 'moment'
import Filter from '@/models/Filter'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import SelectOption from '@/models/interface/SelectOption'
import { startCase } from 'lodash'
import ScheduleItem from './components/ScheduleItem.vue'

@Component({
  components: {
    Widget,
    ScheduleItem,
    IconAction,
    DatePicker,
    CompanyPicker,
    UserPicker,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
    SelectPicker,
  },
})
export default class ScheduleHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public value: Filter = new Filter()

  public enable_charts: boolean = false

  public status_options: SelectOption[] = [
    new SelectOption('Draft', 'draft'),
    new SelectOption('Pending Documents', 'pending_documents'),
    // new SelectOption('Pending Review', 'pending_review'),
    new SelectOption('Confirmed', 'confirmed'),
    new SelectOption('Cancelled', 'cancelled'),
    new SelectOption('Lost', 'lost'),
  ]

  public records: any = {
    ok: [],
    danger: [],
    critical: [],
    warning: [],
    scheduled: [],
    completed: [],
    vcr: [],
  }

  public loading: boolean = false

  public created() {
    this.value.status = ['confirmed', 'pending_documents']
    this.run()
  }

  public toggleCharts() {
    this.loading = true
    setTimeout(() => {
      this.enable_charts = !this.enable_charts
      this.loading = false
    }, 500)
  }

  public run() {
    const api = new Api()
    this.loading = true
    api
      .get('diagnostic/diagnostic', {
        start: this.value.date.start_date_string,
        end: this.value.date.end_date_string,
        agencies: this.value.agencies,
        advertisers: this.value.advertisers,
        orders: this.value.orders,
        line_items: this.value.line_items,
        sales_reps: this.value.sales_reps,
        status: this.value.status,
      })
      .then(result => {
        this.records.ok = []
        this.records.danger = []
        this.records.critical = []
        this.records.warning = []
        this.records.scheduled = []
        this.records.completed = []
        const data = result.data.result
        data.forEach((io: any) => {
          io.items.forEach((item: any) => {
            if (io.type !== 'default') {
              let start = moment(item.start_at)
              let end = moment(item.end_at)

              let pointer = start.clone()
              let itemEnd = end.clone()
              let lockStart = false
              while (pointer.isSameOrBefore(itemEnd)) {
                if (item.metadata.days.includes(pointer.format('dddd').toLowerCase())) {
                  if (!lockStart) {
                    lockStart = true
                    start = pointer.clone()
                  }
                  end = pointer.clone()
                }

                pointer.add(1, 'day')
              }

              item.start_at = start.format('YYYY-MM-DD')
              item.end_at = end.format('YYYY-MM-DD')
            }
            item.issues = this.checkIssues(io, item)
            this.pushItem(io, item)
          })
        })
        this.loading = false
      })
  }

  public setFilter(payload: any) {
    if (payload.key === 'agency_id') {
      this.value.agencies = [payload.value]
    } else if (payload.key === 'client_id') {
      this.value.advertisers = [payload.value]
    } else if (payload.key === 'order_id') {
      this.value.orders = [payload.value]
    } else if (payload.key === 'line_item_id') {
      this.value.line_items = [payload.value]
    } else if (payload.key === 'sales_rep_id') {
      this.value.sales_reps = [payload.value]
    }

    this.run()
  }

  public checkIssues(io: any, item: any) {
    const alert_limit = 500
    const now = moment()
    const start = moment(item.start_at)
    const end = moment(item.end_at)
    const issues: object[] = []
    let impressions = 0

    // Check if campagin is running before start date
    if (item.metrics && item.metrics.impressions && now.isBefore(start)) {
      if (Array.isArray(item.metrics.daily)) {
        impressions = item.metrics.daily.reduce((carry: number, i: number) => carry + i, 0)
      } else {
        impressions = 0
      }
      if (impressions > alert_limit) {
        issues.push({
          message: 'Campaign running before start date',
          level: 'critical',
        })
      }
    }

    // Check if campagin is running after end date
    if (item.metrics && item.metrics.impressions && now.isAfter(end)) {
      let count = 0
      const pointer = now.clone()
      impressions = 6

      while (count >= 0 && now.isAfter(pointer)) {
        impressions += item.metrics.daily[count]
        count--
        pointer.subtract(1, 'day')
      }

      if (impressions > alert_limit) {
        issues.push({
          message: 'Campaign running after end date',
          level: 'critical',
        })
      }
    }

    // Stop Check for scheduled campaigns
    if (now.isBefore(start)) {
      return issues
    }

    // Stop Check for completed campaigns
    if (now.isAfter(end)) {
      return issues
    }

    // Check Order is running but not confirmed
    if (
      item.metrics
      && item.metrics.impressions > 0
      && io.status !== 'confirmed'
      && io.status !== 'pending_documents'
    ) {
      issues.push({
        message: 'Order is active but not confirmed, please contact Account Manager',
        level: io.type !== 'default' ? 'danger' : 'warning',
      })
    }

    // Check if it is under pacing
    if (
      item.metrics
      && item.metrics.pacing
      && item.metrics.pacing.required * 0.9 > item.metrics.pacing.current
    ) {
      const pacing = item.metrics.pacing.current / item.metrics.pacing.required
      issues.push({
        message: `Under pacing at ${((pacing - 1) * 100).toFixed(2)}%`,
        level: pacing < 0.7 ? 'danger' : 'warning',
      })
    }

    // Check if it is over pacing
    if (
      item.metrics
      && item.metrics.pacing
      && item.metrics.pacing.current > item.metrics.pacing.required * 1.3
    ) {
      const pacing = item.metrics.pacing.current / item.metrics.pacing.required
      issues.push({
        message: `Over pacing at ${((pacing - 1) * 100).toFixed(2)}%`,
        level: pacing > 1.5 ? 'danger' : 'warning',
      })
    }

    // Check Low Completion Rate
    if (item.metrics && item.metrics.completed && item.metrics.completion_rate < 0.95) {
      issues.push({
        message: `Low completion rate ${(item.metrics.completion_rate * 100).toFixed(2)}%`,
        level: item.metrics.completion_rate < 0.9 ? 'danger' : 'warning',
      })
    }

    // Check for Low target VCR
    if (
      io.type !== 'default'
      && item.metrics
      && item.metrics.completed
      && item.metrics.completed / item.impressions < 0.9
    ) {
      const expected_completed = item.metrics.impressions * 0.9
      if (item.metrics.completed < expected_completed) {
        issues.push({
          message: `Low Target Completed Views ${(
            (item.metrics.completed / item.impressions)
            * 100
          ).toFixed(2)}%`,
          level: 'vcr',
        })
      }
    }

    // Check not running
    if (item.metrics && item.impressions > item.metrics.impressions) {
      if (!item.metrics.daily || item.metrics.daily.length === 0) {
        issues.push({
          message: 'No activity in the past 7 days',
          level: 'critical',
        })
      } else {
        impressions = item.metrics.daily[6] + item.metrics.daily[5] + item.metrics.daily[4]

        if (impressions === 0) {
          issues.push({
            message: 'No activity in the past 3 days',
            level: 'critical',
          })
        }
      }
    }

    return issues
  }

  private lineItemSpotIndex(media_plan_start: string, start_at: string, index: number): number {
    let pointer = moment(media_plan_start)
      .startOf('week')
      .add(index, 'weeks')
      .add(1, 'day')
    let start = moment(start_at)
      .startOf('week')
      .add(1, 'day')

    if (index > 0) {
      start
        .add(index, 'weeks')
        .startOf('week')
        .add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  public pushItem(io: any, item: any) {
    let impressions = [0, 0, 0, 0, 0, 0, 0]
    let pacing = [0, 0, 0, 0, 0, 0, 0]

    if (item.metrics && item.metrics.daily) {
      impressions = item.metrics.daily
      const p = item.metrics.pacing.required.toFixed(0)
      pacing = [p, p, p, p, p, p, p]
    }

    const now = moment()
    const start = moment(item.start_at)
    const end = moment(item.end_at)
    let type = ''

    let week = this.lineItemSpotIndex(io.start_at, item.start_at, 0)

    let name = `${item.name}: ${start.format('MM/DD')} - ${end.format('MM/DD')}`
    if (item.metadata.program_name) {
      name = `${item.metadata.program_name} (Week ${week}): ${start.format('MM/DD')} - ${end.format(
        'MM/DD',
      )}`
    }

    const data = {
      order_id: io.id,
      line_item_id: item.id,
      code: `${String(io.number).padStart(4, '0')}-${String(item.number).padStart(4, '0')}`,
      type: startCase(io.type.replace('_', ' ')),
      order: io.name,
      name,
      status: io.status,
      client: io.client,
      client_id: io.client_id,
      sales_rep: io.sales_rep ?? 'N/A',
      sales_rep_id: io.sales_rep_id,
      agency: io.agency ?? 'N/A',
      agency_id: io.agency_id,
      impressions: item.impressions,
      completed: item.metrics ? item.metrics.completed ?? 0 : 0,
      completion_rate: item.metrics ? item.metrics.completion_rate ?? 0 : 0,
      total_impressions: item.metrics.impressions,
      start_at: item.start_at,
      end_at: item.end_at,
      days:
        io.type !== 'default'
          ? item.metadata.days
            .map((day: string) => day.slice(0, 1).toUpperCase() + day.slice(1, 3))
            .join(', ')
          : 'Sun, Mon, Tue, Wed, Thu, Fri, Sat',
      notes: item.notes ?? 'N/A',
      issues: item.issues,
      metrics: item.metrics,
      chart_series: [
        {
          name: 'Required Pacing',
          type: 'line',
          data: pacing,
        },
        {
          name: 'Impressions',
          type: 'bar',
          data: impressions,
        },
      ],
    }

    if (item.issues.length === 0) {
      if (now.isBefore(start)) {
        type = 'scheduled'
      } else if (now.isAfter(end)) {
        type = 'completed'
      } else {
        type = 'ok'
      }
    } else if (item.issues.some((i: any) => i.level === 'critical')) {
      type = 'critical'
    } else if (item.issues.some((i: any) => i.level === 'danger')) {
      type = 'danger'
    } else if (item.issues.some((i: any) => i.level === 'warning')) {
      type = 'warning'
    } else {
      type = 'warning'
    }
    this.records[type].push(data)
    if (item.issues.some((i: any) => i.level === 'vcr')) {
      this.records.vcr.push(data)
    }
  }
}
